import React from 'react';
import './index.css';
import { Autocomplete, Box, Button, Input, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Constants } from '../Constants';
import MaterialSelect from '../../shared/UI/components/materialSelect/MaterialSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { endOfYear, add as addDays, sub as subDays, format } from 'date-fns';
import { isBlank } from '../Services';
import dayjs from 'dayjs';

export default function LocationModal({ modalTitle, location, user, setLocationName, setDescription, setDateRange, setAccess, setMembers, users, saveLocation, cancelSaveLocation }) {
	const style = {
		position: 'absolute',
		top: '100px',
		left: '25%',
		// transform: 'translate(-50%, -50%)',
		width: 450,
		// height: 395,
		height: 490,
		bgcolor: '#fff',
		color: '#000',
		border: '2px solid white',
		boxShadow: 24,
		p: 2,
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'content-box',
	};

	const isMenuDisabled = false;
	// const options = ['Public Restricted', 'Private'];
	const accessMenuOptions = [
		{
			title: 'Public Restricted',
			value: 'publicAccess',
		},
		{
			title: 'Private',
			value: 'private',
		},
	];

	if (!location || !user) {
		return '';
	}

	const setLocationNameCb = (event) => setLocationName(event.target.value);
	const setDescriptionCb = (event) => setDescription(event.target.value);
	const setStartDate = (date) => setDateRange([date ? date['$d'] : '', null]);
	const setEndDate = (date) => setDateRange([null, date['$d']]);

	// const [startDate, endDate] = [, ];

	let startDate = dayjs(format(location.dateRange[0].start_date ? new Date(location.dateRange[0].start_date) : subDays(new Date(), { years: 7 }), Constants.DATE_FORMATS.DATE_FORMAT));
	let endDate = dayjs(format(location.dateRange[0].end_date ? new Date(location.dateRange[0].end_date) : endOfYear(new Date()), Constants.DATE_FORMATS.DATE_FORMAT));

	let minDate = dayjs(format(new Date(2016, 1, 1), Constants.DATE_FORMATS.DATE_FORMAT));
	let maxDate = dayjs(format(addDays(new Date(), { years: 1 }), Constants.DATE_FORMATS.DATE_FORMAT));

	const usersOptions = location?.members && location.members.length !== 0 ? [...['Everyone'], ...users] : users;

	const setAccessCb = (event) => {
		setAccess(event.target.value);
	};
	const setMembersCb = (event, newValue) => setMembers(newValue);

	let dateFormat = user && user.units === 'metric' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	// const color = "rgba(0, 0, 0, 0.75)";

	const renderSelectedAccess = (data) => {
		return <span> {data}</span>;
	};

	return (
		<Box sx={style} className="aoi-modal">
			<Typography className="modal-title" variant="h6" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.32px' }}>
				{modalTitle}
			</Typography>

			<span className="title" style={{ marginTop: '0' }}>
				{'Name'}
			</span>

			<Input fullWidth placeholder="Add name for location" onChange={setLocationNameCb} defaultValue={location.name} className="location-info" />

			<span className="title">{'Description'}</span>

			<TextareaAutosize minRows={2} maxRows={4} placeholder="Location description (optional)" defaultValue={location.description} onChange={setDescriptionCb} style={{ minHeight: '49px' }} className="location-info" />

			<div className="size-block">
				<span className="title"> {'AOI Size: '} </span>
				<span className="title" style={{ background: '#f5f5f5' }}>
					{' '}
					{`${Number(location.polygonArea * Constants.UNITS[user.units][Constants.AREA_UNIT[user.units]].factor).toFixed(Constants.UNITS[user.units][Constants.AREA_UNIT[user.units]].decimals) || 'N/A'} ${Constants.UNITS[user.units][Constants.AREA_UNIT[user.units]].label}`}{' '}
				</span>
			</div>

			<div className="date-block">
				<div>
					<span className="title">{'Start'}</span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker format={dateFormat} className="modify-modal-date-picker" minDate={minDate} maxDate={maxDate} onChange={setStartDate} value={startDate} />
					</LocalizationProvider>
				</div>

				<div>
					<span className="title">{'End'}</span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker format={dateFormat} className="modify-modal-date-picker" onChange={setEndDate} minDate={startDate || minDate} maxDate={maxDate} value={endDate} />
					</LocalizationProvider>
				</div>
			</div>

			<div style={{ marginTop: '16px' }}>
				<span className="title">{'Access restriction'}</span>

				<MaterialSelect
					selectStyle={{ height: '29px', width: '418px', borderRadius: '4px', color: 'rgba(0,0,0,0.75)', background: '#F5F5F5', padding: '4px 0px' }}
					options={accessMenuOptions}
					className={'aoi-select-container'}
					handleChange={setAccessCb}
					menuItemClassName={'access-menu-item'}
					isMenuDisabled={isMenuDisabled}
					value={location.publicAccess ? accessMenuOptions[0].value : accessMenuOptions[1].value}
					renderValue={renderSelectedAccess}
				/>
			</div>

			{location.publicAccess ? (
				<div style={{ height: '67px' }}>
					<span className="title">{'Users'}</span>

					<Autocomplete
						multiple
						options={usersOptions}
						getOptionLabel={(option) => option}
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						// value={isBlank(location.members) ? ['Everyone'] : location.members}
						filterSelectedOptions
						renderInput={(params) => <TextField {...params} variant="outlined" />}
						onChange={setMembersCb}
						className="aoi-users-select"
						classes={{ listbox: 'users-list' }}
						value={isBlank(location.members) ? ['Everyone'] : location.members}
					/>
				</div>
			) : (
				''
			)}

			<div className="action-buttons">
				<Stack spacing={2} direction="row">
					<Button variant="text" onClick={cancelSaveLocation}>
						{'Cancel'}
					</Button>
					<Button variant="contained" onClick={saveLocation} >
						{' '}
						{'Save'}{' '}
					</Button>
				</Stack>
			</div>
		</Box>
	);
}
