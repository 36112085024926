import React from 'react';
import './index.css';
import { MenuItem, Select } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

export default function AlertsFilters({ timeFilters = [], zones = [], alertsFilter, setAlertsFilter }) {
	const onZoneChange = (event) => {
		if (event?.target?.value) {
			setAlertsFilter({ ...alertsFilter, zone: event.target.value });
		}
	};
	const onTimeFilterChange = (event) => {
		if (event?.target?.value) {
			setAlertsFilter({ ...alertsFilter, time: event.target.value });
		}
	};

	return (
		<div style={{ padding: '9px 8px 13px 16px', height: '41px', display: 'flex', width: '100%' }}>
			<Select value={alertsFilter.time} className="select-period" onChange={onTimeFilterChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
				{timeFilters.map((value) => (
					<MenuItem key={'c' + Math.floor(Math.random() * 99999999999) + value} value={value}>
						<AccessTimeIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {value}</span>
					</MenuItem>
				))}
			</Select>

			<Select value={alertsFilter.zone} className="select-zones" onChange={onZoneChange} style={{ minWidth: '90px', maxWidth: '200px' }} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
				{zones.map((zone, index) => (
					<MenuItem key={zone + index} value={zone}>
						<LocationSearchingIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {zone}</span>
					</MenuItem>
				))}
			</Select>
		</div>
	);
}
