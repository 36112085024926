/**
 * Description of credentials.js
 * 
 * This module contains constant credential values used throughout the system
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

export const Credentials = Object.freeze({
	MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiYmd3dCIsImEiOiJjbG9jdzZkZ2QwMmhtMmtwOG15N3Q0NWg5In0.kt6eRYNF3F_2RXqCeBAGcw',
	ART_USER: 'gabriel.rozman@bgtechs.com',
	ART_PWD: 'bgt1gabriel!',
});
