import React, { useEffect, useMemo } from 'react';
import { Box, Popper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SensorsEmptyState from './components/emptyState/SensorsEmptyState';
import './components/index.css';

import SensorsList from '../../../../shared/icons/sensors/listMenu.png';
import SensorsListMenu from './components/sensorsListMenu/SensorsListMenu';
import SensorsCharts from './components/sensorsCharts/SensorsCharts';
import { getArtsDataToDisplay } from '../../../../services/artServices';
import TabsChart from './components/tabsChart/TabsChart';
import { SENSORS_FIRST_CHARTS_LABELS, SENSORS_SECOND_CHARTS_LABELS, SENSORS_THIRD_CHART_LABELS, TRENDS_LINES_ARTS_COLOURS, TRENDS_LINES_ARTS_COLOURS_RI, TRENDS_LINES_ARTS_COLOURS_THIRD_CHART } from './constants/sensorsConstants';

export default function SensorsData({ artData, availableSensors, showAverageSensorsData, setShowAvergaeSensorsData, setAvailableSensors, dateRange }) {
	const sensorsTitle = 'Sensors';
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showContent, setShowContent] = React.useState(true);
	const [open, setOpen] = React.useState(false);
	const [numOfSelectedSensors, setNumOfSelectedSensors] = React.useState(false);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(anchorEl ? anchorEl : event.currentTarget);
		setOpen(!open);
	};
	const id = open ? 'simple-popper' : undefined;
	const sensorsData = useMemo(() => getArtsDataToDisplay([...artData], [...availableSensors], showAverageSensorsData, dateRange), [availableSensors, showAverageSensorsData, dateRange, dateRange.id, artData]);
	useEffect(() => {
		let counter = 0;
		availableSensors.forEach((element) => {
			if (element.selected) {
				counter++;
			}
		});
		if (counter !== numOfSelectedSensors) {
			setNumOfSelectedSensors(counter);
		}
	}, [numOfSelectedSensors, availableSensors]);
	let selectedSensorsLabel = '';

	if (numOfSelectedSensors === artData?.length) {
		selectedSensorsLabel = `All ${numOfSelectedSensors} Sensors `;
	} else {
		selectedSensorsLabel = `${numOfSelectedSensors} of ${artData.length} Sensors`;
	}

	const iconsListContainerStyle = { width: '143px', height: '17px', display: 'flex', alignItems: 'center' };
	const IconList = (
		<div style={iconsListContainerStyle} onClick={handleClick}>
			<img height={'16px'} width={'16px'} src={SensorsList} alt="empty-list" />
			<span style={{ marginLeft: '3px' }}>{selectedSensorsLabel}</span>

			<ExpandMoreIcon style={{ height: '16px', width: '16px' }} />

			<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
				<Box style={{ minWidth: '176px', maxWidth: '252px', height: '269px', flexDirection: 'column', background: 'white', whiteSpace: 'nowrap' }}>
					<SensorsListMenu availableSensors={availableSensors} showAverageSensorsData={showAverageSensorsData} setShowAvergaeSensorsData={setShowAvergaeSensorsData} setAvailableSensors={setAvailableSensors} open={open} setOpen={setOpen} />
				</Box>
			</Popper>
		</div>
	);

	let emptyStateTitle = 'No data for the selected sensors';
	if (!sensorsData && availableSensors) {
		emptyStateTitle = 'No selected data sources to display';
	}
	return (
		<div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.16)', borderTop: ' 1px solid rgba(0, 0, 0, 0.16)' }}>
			<div className="sensors-data-container" onClick={() => setShowContent(!showContent)}>
				{' '}
				<ExpandMoreIcon style={{ height: '16px', width: '16px', transform: showContent ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
				{sensorsTitle}
				<div style={{ width: '100%' }} className="sensors-menu">
					{IconList}
				</div>
			</div>

			{sensorsData ? <SensorsCharts sensorsData={sensorsData.firstChartData} showContent={showContent} labels={SENSORS_FIRST_CHARTS_LABELS} colours={TRENDS_LINES_ARTS_COLOURS} /> : <SensorsEmptyState emptyStateTitle={emptyStateTitle} />}
			{sensorsData ? <SensorsCharts sensorsData={sensorsData.secondChartData} showContent={showContent} labels={SENSORS_SECOND_CHARTS_LABELS} colours={TRENDS_LINES_ARTS_COLOURS_RI} /> : ''}
			{sensorsData && showContent ? <TabsChart sensorsData={sensorsData.thirdChartData} showContent={showContent} labels={SENSORS_THIRD_CHART_LABELS} colours={TRENDS_LINES_ARTS_COLOURS_THIRD_CHART} /> : ''}
		</div>
	);
}
