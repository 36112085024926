import React from 'react';
import './index.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Constants } from '../../../Constants';
import { format } from 'date-fns';
export default function LakeInfo({ location, user }) {
	return (
		<div className="aoi-info">
			<InfoOutlinedIcon style={{ height: '14px', width: '14px', marginRight: '5px' }} />
			{`Monitored from: ${format(location.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT)} to
            ${format(location.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT)} | Size ${Number(location.polygonArea * Constants.UNITS[user.units][Constants.AREA_UNIT[user.units]].factor).toFixed(Constants.UNITS[user.units][Constants.AREA_UNIT[user.units]].decimals) || 'N/A'} ${
				Constants.UNITS[user.units][Constants.AREA_UNIT[user.units]].label
			}`}
		</div>
	);
}
