import React from 'react';
import { VictoryVoronoiContainer } from 'victory';
import { victoryLGVTheme } from '../../../../../victoryLGVTheme';
import LinesChart from '../linesChart/LinesChart';
import SensorsLabels from '../sensorsLabels/SensorsLabels';

export default function SensorsCharts({ showContent, sensorsData, labels, colours }) {
	const getLineStroke = (index, param) => {
		return colours[param][index];
	};

	const containerComponent = <VictoryVoronoiContainer />;

	if (!showContent) return '';

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{labels ? <SensorsLabels labels={labels} /> : ''}

			{/* sensors chart start */}

			<LinesChart victoryLGVTheme={victoryLGVTheme} width={600} height={240} sensorsData={sensorsData} getLineStroke={getLineStroke} containerComponent={containerComponent} padding={{ left: 70, right: 25, top: 30, bottom: 30 }} clipPadding={{ top: 5, right: 10 }} />

			{/* sensors chart end */}
		</div>
	);
}
