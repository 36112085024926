import React, { useState } from 'react';
import './index.css';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SensorsCharts from '../sensorsCharts/SensorsCharts';

export default function TabsChart({ sensorsData, showContent, labels, colours }) {
	const [selectedTab, setSelectedTab] = useState(labels[0]);

	const onButtonClick = (element) => {
		if (element && element.value !== selectedTab.value) {
			setSelectedTab(element);
		}
	};

	let showChart = sensorsData && sensorsData[selectedTab.value] ? true : false;
	return (
		<div className="tabs-chart-container">
			<ButtonGroup className="arts-tabs-container">
				{labels.map((el, index) => (
					<Button variant={el.value === selectedTab.value ? 'contained' : ''} key={index} onClick={() => onButtonClick(el)} className={el.value === selectedTab.value ? 'chart-tabs selected' : 'chart-tabs'}>
						{el.key}
					</Button>
				))}
			</ButtonGroup>
			{showChart ? <SensorsCharts sensorsData={sensorsData[selectedTab.value]} showContent={showContent} labels={''} colours={colours} /> : ''}
		</div>
	);
}
