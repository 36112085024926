import React from 'react';
import './index.css';
import Alert from './alert/Alert';
import { Tooltip } from '@mui/material';

export default function AlertsList({ alerts, readAlerts }) {
	if (!alerts) return '';

	let alertsTitle = (title) => <span style={{ fontSize: '20px', height: '20px' }}>{title}</span>;
	return (
		<div className="alerts-block">
			{alerts.map((el, index) => (
				<Tooltip title={alertsTitle(el.value?.description)} key={index} placement="top">
					<span>
						<Alert data={el} readAlerts={readAlerts} />
					</span>
				</Tooltip>
			))}
		</div>
	);
}
