import { format, sub as subDays } from 'date-fns';
import { Constants } from '../components/Constants';

const getRandom = (max) => {
	return Math.floor(Math.random() * max);
};

export const getSensorsAvailableDates = () => {
	let data = [];

	for (let i = 500; i > 0; i--) {
		let date = new Date(subDays(new Date(), { days: i }));
		date = format(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		data.unshift(date.toString());
	}

	return data;
};
const generateArtDatapoints = () => {
	let data = [];

	for (let i = 500; i > 0; i--) {
		let date = new Date(subDays(new Date(), { days: i }));

		if (i < 9) {
			for (let j = 1; j < 22; j++) {
				data.push({
					handshaketime: '',
					id: '',
					art_id: '',
					location_id: '',
					buoytemp: '',
					uarterror: '',
					utctime: '',
					waterlight: '',
					ps: '',

					handshaketime: new Date(date.setHours(j)),
					airtemp: getRandom(100),
					watertemp: getRandom(50),
					chlora: getRandom(100),
					light: getRandom(100),
					phycocyanin: getRandom(100),
					sigstrength: '',
					turbidity: getRandom(50),
					ri: getRandom(100),
					battsoc: '', // batteryStatus requested, this is provided
					battvolts: '', // batteryStatus requested, this is provided
					ph: getRandom(100), // not provided
					do: getRandom(100), // not provided
					enabled: true,
					brusherror: '', //x
					codeversion: '', //x
					fixok: '', //x
					leakdetect: '', //x
					panelvolts: '', //x
					sigquality: '', //x
				});
			}
		} else {
			data.push({
				handshaketime: '',
				id: '',
				art_id: '',
				location_id: '',
				buoytemp: '',
				uarterror: '',
				utctime: '',
				waterlight: '',
				ps: '',

				handshaketime: new Date(date),
				airtemp: getRandom(100),
				watertemp: getRandom(100),
				chlora: getRandom(100),
				light: getRandom(100),
				phycocyanin: getRandom(100),
				sigstrength: '',
				turbidity: getRandom(100),
				ri: getRandom(100),
				battsoc: '', // batteryStatus requested, this is provided
				battvolts: '', // batteryStatus requested, this is provided
				ph: getRandom(100), // not provided
				do: getRandom(100), // not provided
				enabled: true,
				brusherror: '', //x
				codeversion: '', //x
				fixok: '', //x
				leakdetect: '', //x
				panelvolts: '', //x
				sigquality: '', //x
			});
		}
	}
	return data;
};

export const artMockData = [
	{
		artId: '1111',

		locationsId: '',

		latitude: 30.144048204590487,

		longitude: -81.71945760390892,

		data: generateArtDatapoints(),
	},
	{
		artId: '22222',

		locationsId: '',

		latitude: 30.153251480152168,

		longitude: -81.70838544471196,

		data: generateArtDatapoints(),
	},

	{
		artId: '33333',

		locationsId: '',

		latitude: 30.12856325879072,

		longitude: -81.74132293453829,

		data: generateArtDatapoints(),
	},
	{
		artId: '444444',

		locationsId: '',

		latitude: 30.113185504749534,

		longitude: -81.75278959202717,

		data: generateArtDatapoints(),
	},
];
