import React from 'react';

export default function SensorsLabels({ labels }) {
	return (
		<div style={{ display: 'flex', flexDirection: 'row', marginLeft: '22px' }}>
			{labels.map((el, index) => {
				return (
					<div className="chl-chart-label" key={index}>
						<span>{el.title}</span>
						<div style={{ background: el.color, height: '4px', width: '11px' }}></div>
					</div>
				);
			})}
		</div>
	);
}
