/**
 * Description of CoordinatesControl.js
 *
 * This module is a mapbox control that displays the coordinates of the mouse cursor.
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import { Constants } from '../components/Constants';

// import { Constants } from '../components/Constants';

class CoordinatesControl {
	constructor(state, isTablet) {
		this.longitude = state.longitude;
		this.latitude = state.latitude;
		this.units = state.user.units;
		// this.showNewUI = showNewUI;
		this.isTablet = isTablet;
	}

	onAdd(map) {
		const _this = this;
		_this.map = map;
		_this.mapCenter = _this.map.getCenter();
		_this.coordinatesControl = document.createElement('div');
		let tabletClass = '';
		tabletClass = ' new-ui ';
		if (this.isTablet) {
			tabletClass += ' tablet';
		}
		_this.coordinatesControl.className = 'coordinates ' + tabletClass;
		_this.coordinatesControl.id = 'coordinates';

		if (this.isTablet) {
			_this.coordinatesControl.innerHTML = `Pitch: ${`${Number(_this.map.getPitch()).toFixed(1)}°`} · Bearing: ${Number(_this.map.getBearing()).toFixed(2)}° N · Zoom: ${Number(_this.map.getZoom()).toFixed(2)} ·`;
		} else {
			_this.coordinatesControl.innerHTML = ` Long: ${Number(_this.mapCenter.lng).toFixed(5)} · Lat: ${Number(_this.mapCenter.lat).toFixed(5)} · Elevation: ${Number(
				_this.map.queryTerrainElevation(_this.latitude ? [_this.longitude, _this.latitude] : _this.map.getCenter()) * Constants.UNITS[this.units].elevation.factor
			).toFixed(Constants.UNITS[this.units].elevation.decimals)}${Constants.UNITS[_this.units].elevation.label} above sea level · Pitch: ${`${Number(_this.map.getPitch()).toFixed(1)}°`} · Bearing: ${Number(_this.map.getBearing()).toFixed(2)}° N · Zoom: ${Number(_this.map.getZoom()).toFixed(2)} ·`;
		}

		_this.container = document.createElement('div');
		_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-coordinates';
		_this.container.appendChild(_this.coordinatesControl);
		return _this.container;
		// }
		//  else {
		// 	_this.coordinatesControl.innerHTML = `Longitude: ${Number(_this.mapCenter.lng).toFixed(5)} | Latitude: ${Number(_this.mapCenter.lat).toFixed(5)} | Elevation: ${Number(
		// 		_this.map.queryTerrainElevation(_this.latitude ? [_this.longitude, _this.latitude] : _this.map.getCenter()) * Constants.UNITS[this.units].elevation.factor
		// 	).toFixed(Constants.UNITS[this.units].elevation.decimals)}${Constants.UNITS[_this.units].elevation.label} above sea level | Pitch: ${`${Number(_this.map.getPitch()).toFixed(1)}°`} | Bearing: ${Number(_this.map.getBearing()).toFixed(2)}° N| Zoom: ${Number(_this.map.getZoom()).toFixed(2)} | Scale:`;
		// 	_this.container = document.createElement('div');
		// 	_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-coordinates';
		// 	_this.container.appendChild(_this.coordinatesControl);
		// 	return _this.container;
		// }
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}

	updateCoordinates(center, state) {
		let bearing = Number(this.map.getBearing());
		let mapCenter = center || this.map.getCenter();

		if (bearing >= 0 && bearing <= 22.5) {
			bearing = `${bearing.toFixed(2)}° N`;
		} else if (bearing > 22.5 && bearing <= 67.5) {
			bearing = `${bearing.toFixed(2)}° NE`;
		} else if (bearing > 67.5 && bearing <= 112.5) {
			bearing = `${bearing.toFixed(2)}° E`;
		} else if (bearing > 112.5 && bearing <= 157.5) {
			bearing = `${bearing.toFixed(2)}° SE`;
		} else if (bearing > 157.5 && bearing <= 180) {
			bearing = `${bearing.toFixed(2)}° S`;
		} else if (bearing <= -157.5 && bearing > -180) {
			bearing = `${(360 + bearing).toFixed(2)}° S`;
		} else if (bearing <= -112.5 && bearing > -157.5) {
			bearing = `${(360 + bearing).toFixed(2)}° SW`;
		} else if (bearing <= -67.5 && bearing > -112.5) {
			bearing = `${(360 + bearing).toFixed(2)}° W`;
		} else if (bearing <= -22.5 && bearing > -67.5) {
			bearing = `${(360 + bearing).toFixed(2)}° NW`;
		} else if (bearing <= -0 && bearing >= -22.5) {
			bearing = `${(360 + bearing).toFixed(2)}° N`;
		}

		if (this.isTablet) {
			this.coordinatesControl.innerHTML = `Pitch: ${Number(this.map.getPitch()).toFixed(1)}° · Bearing: ${bearing} · Zoom: ${Number(this.map.getZoom()).toFixed(2)} `;
		} else {
			// this.coordinatesControl.innerHTML = `Longitude: ${Number(mapCenter.lng).toFixed(5)} | Latitude: ${Number(mapCenter.lat).toFixed(5)} | Elevation: ${Number(this.map.queryTerrainElevation(mapCenter, { exaggerated: false }) * Constants.UNITS[this.units].elevation.factor).toFixed(
			// 	Constants.UNITS[this.units].elevation.decimals
			// )}${Constants.UNITS[this.units].elevation.label} above sea level |  Pitch: ${Number(this.map.getPitch()).toFixed(1)}° | Bearing: ${bearing} | Zoom: ${Number(this.map.getZoom()).toFixed(2)} | Scale:`;

			this.coordinatesControl.innerHTML = `Long: ${Number(mapCenter.lng).toFixed(5)} · Lat: ${Number(mapCenter.lat).toFixed(5)} · Elevation: ${Number(this.map.queryTerrainElevation(this.latitude ? [this.longitude, this.latitude] : this.map.getCenter()) * Constants.UNITS[this.units].elevation.factor).toFixed(
				Constants.UNITS[this.units].elevation.decimals
			)}${Constants.UNITS[this.units].elevation.label} above sea level · Pitch: ${Number(this.map.getPitch()).toFixed(1)}° · Bearing: ${bearing} · Zoom: ${Number(this.map.getZoom()).toFixed(2)} `;
		}
	}
}

export default CoordinatesControl;
