import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './index.css';
import { MODAL_DISPLAY_TIME } from '../../../../components/Constants';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});
export default function MaterialNotification({ open, close, msg, severity }) {
	return (
		<div className="notification-container">
			<Snackbar open={open} autoHideDuration={MODAL_DISPLAY_TIME} onClose={close} className="notification-block">
				<Alert onClose={close} severity={severity} sx={{ width: '100%' }}>
					{msg}
				</Alert>
			</Snackbar>
		</div>
	);
}
