import * as React from 'react';
import MaterialSelect from '../../../../shared/UI/components/materialSelect/MaterialSelect';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import CheckIcon from '@mui/icons-material/Check';
import { PLANET_MENU_VALUE, SATELLITES, SENTINEL3 } from '../../../Constants';

export default function SatteliteSelect({ options, menuItemClassName, satelliteView, setSatelliteView, isMenuDisabled, fontStyleClassName }) {
	const handleChange = (event) => {
		const newValue = event.target.value;
		if (newValue === satelliteView) return;

		setSatelliteView(newValue);
	};

	const customizedOptions = options.map((el, index) => {
		return {
			...el,
			title:
				satelliteView === el.value ? (
					<>
						{' '}
						<CheckIcon style={{ width: '14px', height: '14px', marginRight: '6px' }} /> <span className={fontStyleClassName}> {el.title} </span>{' '}
					</>
				) : (
					<>
						<span className={fontStyleClassName} style={{ margin: '0px 20px' }}>
							{' '}
							{el.title}{' '}
						</span>
					</>
				),
		};
	});

	const renderSelectedLocation = (data) => {
		const style = isMenuDisabled ? { height: '14px', width: '14px', marginRight: '5px', fill: 'rgba(0,0,0,0.5)' } : { height: '14px', width: '14px', marginRight: '5px' };

		let title = data === SATELLITES.copernicus ? SENTINEL3 : PLANET_MENU_VALUE;
		return (
			<span className="selected-satellite">
				{' '}
				<SatelliteAltIcon style={style} /> {title}
			</span>
		);
	};

	return (
		<>
			<MaterialSelect
				selectStyle={{ height: '28px', borderRadius: '4px', background: '#F5F5F5', padding: '4px 0px' }}
				value={satelliteView}
				options={customizedOptions}
				className={'select-container'}
				handleChange={handleChange}
				renderValue={renderSelectedLocation}
				menuItemClassName={menuItemClassName}
				isMenuDisabled={isMenuDisabled}
			/>
		</>
	);
}
