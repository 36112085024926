import React from 'react';
import './index.css';

import EmptyState from '../../../../../../shared/icons/activities/emptyState.png';

export default function SensorsEmptyState({ emptyStateTitle }) {
	return (
		<div className="sensors-empty-state-container">
			<img height={'24px'} width={'24px'} src={EmptyState} alt="empty-list" />
			<span className="empty-state-title">{emptyStateTitle}</span>
		</div>
	);
}
