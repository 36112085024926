import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function DateBlock({ datePickerTitle, titleClass, dateClass, title, datePickerProps }) {
	return (
		<Box className={titleClass}>
			<Typography id="modal-modal-title" variant="h6" style={{ color: '#000000', opacity: '0.75' }} className={datePickerTitle}>
				{title}
			</Typography>

			<LocalizationProvider dateAdapter={AdapterDayjs} style={{ paddingTop: '0' }} className={dateClass}>
				<DemoContainer components={['DatePicker']}>
					<DatePicker {...datePickerProps} />
				</DemoContainer>
			</LocalizationProvider>
		</Box>
	);
}
