import { sub as subDays } from 'date-fns';
import { ALERTS_ALL_ZONES_FILTER, ALERTS_READ_STATUS, ALERTS_TIME_DEFAULT_FILTER, ALERTS_UNREAD_STATUS, ALERT_SEVERITY_CRITICAL, ALERT_SEVERITY_MAJOR, ALERT_SEVERITY_MINOR, ALERT_SEVERITY_MODERATE, ALERT_SEVERITY_WARNING, UPDATE_NOTIFICATIONS_URL } from '../components/Constants';
import { fetch } from '../components/Services';

export const getAlertsDataFromNotifications = (data, location) => {
	//if the structure of the claerts changes -> update it here.
	const locationsId = location?.locationsId;
	let alerts = [];
	for (const element of data) {
		if (element.topic === 'alert') {
			if (element.value?.locationsId === locationsId) {
				alerts.push(element);
			}
		}
	}
	return alerts;
};

export const getZonesList = (data) => {
	//get zones from alerts list.
	let result = {};
	result[ALERTS_ALL_ZONES_FILTER] = ALERTS_ALL_ZONES_FILTER;
	if (!data || !data.length) {
		result = Object.keys(result);
		return result;
	}

	for (const el of data) {
		if (!result[el.zone]) {
			result[el.value.zone] = el.value.zone;
		}
	}

	result = Object.keys(result);
	return result;
};

export const filterAlerts = (alerts, filters) => {
	if (!alerts || !filters) {
		return alerts;
	}
	let result = [...alerts];
	let baseDate = new Date();

	result = filterAlertsViaTime(result, filters.time, baseDate);

	if (filters.zone && filters.zone !== ALERTS_ALL_ZONES_FILTER && alerts.length > 0) {
		//filter via zone value
		result = result.filter((el) => el.value.zone === filters.zone);
	}

	return result;
};

export const filterAlertsViaTime = (alerts, time, baseDate) => {
	if (!time || alerts.length === 0) return alerts;

	let response = [...alerts];

	switch (time) {
		case 'Past week':
			response = response.filter((_data) => new Date(_data.value.timestamp) > subDays(baseDate, { weeks: 1 }));

			break;
		case 'Past Month':
			response = response.filter((_data) => new Date(_data.value.timestamp) > subDays(baseDate, { months: 1 }));
			break;

		case 'Past 6 months':
			response = response.filter((_data) => new Date(_data.value.timestamp) >= subDays(baseDate, { months: 6 }) && new Date(_data.value.timestamp) <= baseDate);
			break;

		case 'All':
			break;

		default:
			response = [...alerts];
			break;
	}

	return response;
};

export const getTimeFilters = () => {
	return [ALERTS_TIME_DEFAULT_FILTER, 'Past Month', 'Past 6 months', 'All'];
};

export const getUnreadAlerts = (alerts) => {
	if (!alerts) return [];
	let result = alerts.filter((el) => el.value.status === ALERTS_UNREAD_STATUS);
	result = result.map((el) => {
		return { notificationsId: el.value.notificationsId, status: 'read' };
	});
	return result;
};

export const readAllAlertsService = async (alertsNumber, alerts, setAlerts, unreadAlerts) => {
	let selectedAlert = unreadAlerts[0]?.notificationsId;
	selectedAlert = alerts.findIndex((el) => el.value.notificationsId === selectedAlert);
	selectedAlert = alerts[selectedAlert];

	if (alertsNumber > 0 && selectedAlert.value.status === ALERTS_UNREAD_STATUS) {
		try {
			const response = await fetch({
				url: UPDATE_NOTIFICATIONS_URL,
				data: { notifications: unreadAlerts },
			});

			let alertsCopy = [...alerts];

			if (response && response.Success && response.Success.length > 0) {
				for (const element of response.Success) {
					const index = alertsCopy.findIndex((el) => el.value.notificationsId === element);
					if (index >= 0) {
						alertsCopy[index].value.status = ALERTS_READ_STATUS;
					}
				}
			}
			if (response && response.Error && response.Error.length > 0) {
				for (const element of response.Error) {
					const index = alertsCopy.findIndex((el) => el.value.notificationsId === element);
					if (index >= 0) {
						alertsCopy[index].value.status = ALERTS_UNREAD_STATUS;
					}
				}
			}
			setAlerts(alertsCopy);
		} catch (e) {}
	}
	return selectedAlert;
};

export const getAlertsNumberColour = (alerts) => {
	if (!alerts) return 'transparent';

	let values = {};
	for (const alert of alerts) {
		if (alert.value.status === ALERTS_UNREAD_STATUS) {
			values[alert.value.severity] = 1;
		}
	}
	if (values[ALERT_SEVERITY_CRITICAL] || values['high']) {
		return '#631304';
	}
	if (values[ALERT_SEVERITY_MAJOR] || values['warning']) {
		return 'red';
	}
	if (values[ALERT_SEVERITY_MODERATE] || values['ok']) {
		return 'orange';
	}
	if (values[ALERT_SEVERITY_MINOR]) {
		return 'blue';
	}
	if (values[ALERT_SEVERITY_WARNING]) {
		return 'rgba(0, 0, 0, 0.75)';
	}
};
