import React from 'react';
import './index.css';
import { BLOOM, INTERFERENCES } from '../../Constants';
import BloomLegend from './bloomLegend/BloomLegend';
import InterferencesLegend from './interferencesLegend/InterferencesLegend';

export default function SaltWaterLegend({ selectedLayers }) {
	if (selectedLayers?.length === 0) {
		return '';
	}

	let hasBloom = 0;
	let hasInterfernces = 0;

	selectedLayers.forEach((element) => {
		if (element === BLOOM) {
			hasBloom = 1;
		}
		if (element === INTERFERENCES) {
			hasInterfernces = 1;
		}
	});

	if (hasBloom === 0 && hasInterfernces === 0) {
		return '';
	}

	if (hasBloom === 1 && hasInterfernces === 0) {
		//return bloom legend only
		return <BloomLegend />;
	}

	if (hasBloom === 0 && hasInterfernces === 1) {
		//return interfernces legend only
		return <InterferencesLegend />;
	}

	if (hasBloom === 1 && hasInterfernces === 1) {
		//return both legends
		return (
			<>
				{' '}
				<BloomLegend sedimentLegendShown={true} />{' '}
				<div className="legend-divider-salt-water">
					<svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
						<path d="M1 -0.5V42" stroke="white" strokeOpacity="0.3" />
					</svg>
				</div>
				<InterferencesLegend bloomLegendShown={true} />{' '}
			</>
		);
	}

	return '';
}
