import React from 'react';
import './index.css';
export default function BloomLegend({ sedimentLegendShown = false }) {
	let bloomValues = [
		{ colour: '#0454B1', order: 0 },
		{ colour: '#265CB6', order: 1 },
		{ colour: '#00A5CA', order: 2 },
		{ colour: '#56DA41', order: 3 },
		{ colour: '#DDF03B', order: 4 },
		{ colour: '#FDAA36', order: 5 },
		{ colour: '#A02C14', order: 6 },
		{ colour: '#631304', order: 7 },
	];
	const bloomLevelsTitle = 'Bloom levels:';

	return (
		<div className="salt-water-bloom-legend-container" style={sedimentLegendShown ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}>
			<div className="legend-inner-container">
				<span className="legend-text" style={{ marginTop: '14px' }}>
					{' '}
					{bloomLevelsTitle}{' '}
				</span>

				<div style={{ height: '8px', width: '221px', marginLeft: '14px', marginRight: '14px' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '11px' }}>
						{bloomValues.map((el, index) => (
							<div style={{ display: 'flex', flexDirection: 'column', width: '27px' }} key={el.colour + index}>
								<div style={{ background: el.colour, width: '27px', height: '8px' }}></div>
								<span style={{ textAlign: 'center', fontFamily: 'Inter', fontSize: '12px', paddingTop: '2px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>{el.order}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
