import React from 'react';
import ClearSky from '../../../../../shared/icons/weatherIcons/clearSky.png';
import Overcast from '../../../../../shared/icons/weatherIcons/overcast.png';
import Fog from '../../../../../shared/icons/weatherIcons/fog.png';
import Drizzle from '../../../../../shared/icons/weatherIcons/drizzle.png';
import Freezingdrizzle from '../../../../../shared/icons/weatherIcons/freezingDrizzle.png';
import Rainslight from '../../../../../shared/icons/weatherIcons/rainSlight.png';
import Freezingrain from '../../../../../shared/icons/weatherIcons/freezingRain.png';
import Snowfall from '../../../../../shared/icons/weatherIcons/snowfall.png';
import Snowgrains from '../../../../../shared/icons/weatherIcons/snowgrains.png';
import Rainshowers from '../../../../../shared/icons/weatherIcons/rainshowers.png';
import Snowshowers from '../../../../../shared/icons/weatherIcons/snowshowers.png';
import Thunderstorm from '../../../../../shared/icons/weatherIcons/thunderstorm.png';
import Thunderstormheavy from '../../../../../shared/icons/weatherIcons/thunderstormheavy.png';

const iconsMap = {
	0: { icon: ClearSky, alt: 'clear sky' },

	1: { icon: Overcast, alt: '' },
	2: { icon: Overcast, alt: '' },
	3: { icon: Overcast, alt: '' },

	45: { icon: Fog, alt: 'fog' },
	48: { icon: Fog, alt: 'fog' },

	51: { icon: Drizzle, alt: 'drizzle' },
	53: { icon: Drizzle, alt: 'drizzle' },
	55: { icon: Drizzle, alt: 'drizzle' },

	56: { icon: Freezingdrizzle, alt: 'freezing drizzle' },
	57: { icon: Freezingdrizzle, alt: 'freezing drizzle' },

	61: { icon: Rainslight, alt: 'rain slightly' },
	63: { icon: Rainslight, alt: 'rain slightly' },
	65: { icon: Rainslight, alt: 'rain slightly' },

	66: { icon: Freezingrain, alt: 'freezing rain' },
	67: { icon: Freezingrain, alt: 'freezing rain' },

	71: { icon: Snowfall, alt: 'snow fall' },
	73: { icon: Snowfall, alt: 'snow fall' },
	75: { icon: Snowfall, alt: 'snow fall' },

	77: { icon: Snowgrains, alt: 'snow grains' },

	80: { icon: Rainshowers, alt: 'rain showers' },
	81: { icon: Rainshowers, alt: 'rain showers' },
	82: { icon: Rainshowers, alt: 'rain showers' },

	85: { icon: Snowshowers, alt: 'snow showers' },
	86: { icon: Snowshowers, alt: 'snow showers' },

	95: { icon: Thunderstorm, alt: 'thunderstorm' },

	96: { icon: Thunderstormheavy, alt: 'thunderstorm heavy' },
	99: { icon: Thunderstormheavy, alt: 'thunderstorm heavy' },
};

export default function GetWeatherIcon({ wc, isTablet }) {
	if (iconsMap[wc] && iconsMap[wc].icon) {
		return (
			<>
				<img style={isTablet ? { height: '30px', width: '30px' } : {}} src={iconsMap[wc].icon} alt={iconsMap[wc].alt} />
			</>
		);
	}

	return <></>;
}
