/**
 * Description of View360Control.js
 *
 * This module is a mapbox control that allows the user to rotate the map 360 degrees.
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

class View360Control {
	constructor() {
		this.rotate = this.rotate.bind(this);
		this.updateRotation = this.updateRotation.bind(this);
	}

	onAdd(map) {
		const _this = this;
		_this.map = map;
		_this.isRotating = null;
		_this.view360Control = document.createElement('div');
		_this.view360Control.className = 'mapboxgl-ctrl-bgwt view360 hidden';
		_this.view360Control.innerHTML = '360°';
		_this.view360Control.type = 'button';
		_this.view360Control['aria-label'] = 'View 360';
		_this.view360Control.onclick = _this.rotate;
		_this.container = document.createElement('div');
		_this.container.style.cursor = 'pointer';
		_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-view360';
		_this.container.appendChild(_this.view360Control);
		return _this.container;
	}

	updateRotation() {
		let bearing = this.map.getBearing() + 10;

		if (bearing >= 360) {
			bearing = 0;
		}

		this.map.easeTo({ bearing: bearing });
	}

	rotate(event, start = true) {
		event.stopPropagation();

		if (this.isRotating || !start) {
			clearInterval(this.isRotating);
			this.isRotating = null;
		} else {
			this.isRotating = setInterval(this.updateRotation, 50);
		}
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}

	hide() {
		this.view360Control.classList.add('hidden');
	}

	show() {
		this.view360Control.classList.remove('hidden');
	}
}

export default View360Control;
