import React from 'react';
import './index.css';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

export default function MapTooltip({ message }) {
	return (
		<div className="map-meesage-container">
			{' '}
			<WarningOutlinedIcon style={{ fill: '#E2471E', height: '16px', width: '16px' }} /> {message}
		</div>
	);
}
