import React, { useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from './pages/LakeGuardView';
import { AuthContext } from './context/auth';
import Login from './pages/Login';
function Main(props) {
	const token = localStorage.getItem('tokens') ? localStorage.getItem('tokens') : undefined,
		[authTokens, setAuthTokens] = useState(token),
		setTokens = (data) => {
			localStorage.setItem('tokens', data);
			setAuthTokens(data);
		};

	return (
		<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
			<BrowserRouter>
				<div>
					<PrivateRoute exact path="/" component={Home} />
					<Route path="/login" component={Login} />
				</div>
			</BrowserRouter>
		</AuthContext.Provider>
	);
}

export default Main;
