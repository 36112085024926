import _ from 'lodash';

export const isAuthorized = (state, modes) => _.intersection(state?.groups, modes).length > 0;

export const isFloat = (n) => {
	return Number(n) === n && n % 1 !== 0;
};

export const countDecimals = (num) => {
	if (Math.floor(num) === num) return 0;
	return num.toString().split('.')[1].length || 0;
};
