import React from 'react';
import './index.css';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ALERT_SEVERITY_CRITICAL, ALERT_SEVERITY_MAJOR, ALERT_SEVERITY_MINOR, ALERT_SEVERITY_MODERATE, ALERT_SEVERITY_WARNING } from '../../../../Constants';

export default function Alert({ data, readAlerts }) {
	const getAlertIcons = (type) => {
		if (type === 'high' || type === ALERT_SEVERITY_CRITICAL) {
			//delete high once api fully ready
			return <InfoIcon style={{ fill: ' #631304' }} />;
		} else if (type === ALERT_SEVERITY_MAJOR) {
			return <InfoIcon style={{ fill: 'red' }} />;
		} else if (type === 'ok' || type === ALERT_SEVERITY_MODERATE) {
			//delete ok once api fully ready
			return <InfoIcon style={{ fill: 'orange' }} />;
		} else if (type === ALERT_SEVERITY_MINOR) {
			return <InfoIcon style={{ fill: 'blue' }} />;
		} else if (type === ALERT_SEVERITY_WARNING) {
			return <InfoOutlinedIcon color="disabled" />;
		}
	};

	const date = new Date(data.value.timestamp);

	const onClick = async () => {
		const unreadAlerts = [{ notificationsId: data.value.notificationsId, status: data.value.status }];
		await readAlerts(unreadAlerts);
	};

	return (
		<div className="alert-container" onClick={onClick}>
			<div>{getAlertIcons(data.value.severity)}</div>

			<div className="alert-info">
				<span className="alert-title" style={{ fontWeight: data.value.status === 'unread' ? '600' : '400' }}>
					{' '}
					{data.value.title}{' '}
				</span>
				<div className="bottom-alert-info">
					<span className="alert-info-zone"> {data.value.zone} </span>
					<span className="alert-info-date"> {date.toLocaleDateString()} </span>
				</div>
			</div>
		</div>
	);
}
