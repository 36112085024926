import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './index.css';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import MaterialTooltipIconButton from '../materialTooltipIconButton/MaterialTooltipIconButton';

export default function ConfirmModal(props) {
	const { confirmButtonText = 'Continue', cancelButtonText = 'cancel', open, message, message2, confirmButtonCb, cancelButtonCb, title } = props;

	const iconSizeStyle = {
		width: '20px',
		height: '20px',
		marginRight: '8px',
		fill: 'white',
	};

	return (
		<React.Fragment>
			<Dialog open={open} onClose={cancelButtonCb} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<div className="dialog-container" style={{ height: '205px' }}>
					<DialogTitle id="alert-dialog-title" className="confirm-modal-title">
						<MaterialTooltipIconButton title={'Missing Data'} iconButtonStyle={{ paddingLeft: '0' }} edge={'end'} Icon={ErrorRoundedIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} />
						{title}
					</DialogTitle>
					<DialogContent style={{ paddingLeft: '0', paddingBottom: '0px', display: 'contents' }}>
						<DialogContentText id="alert-dialog-description" className="confirm-modal-message">
							{' '}
							{message}
							<br />
							{message2}
						</DialogContentText>
					</DialogContent>
					<DialogActions className="actions-class">
						<Button autoFocus onClick={confirmButtonCb} className="confirm-button" variant="contained">
							{confirmButtonText}
						</Button>
						<Button className="cancel-button" onClick={cancelButtonCb}>
							{cancelButtonText}
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		</React.Fragment>
	);
}
