import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './index.css';

export default function MaterialSelect(props) {
	const { className, options, selectStyle = {}, value, handleChange, renderValue, menuItemClassName, isMenuDisabled = false } = props;

	return (
		<FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth className={className}>
			<Select disabled={isMenuDisabled} style={selectStyle} value={value || ''} native={false} renderValue={renderValue} onChange={handleChange}>
				{options &&
					options.map((el, index) => (
						<MenuItem className={menuItemClassName} value={el.value} key={index} disabled={el.disabled}>
							{el.title}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
}
