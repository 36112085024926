import React from 'react';
import './index.css';
export default function InterferencesLegend({ bloomLegendShown }) {
	let interferncesValues = ['Low', 'Moderate', 'High'];
	let colours = ['#E4E0E0', '#CAC7C7', '#B1AEAE', '#989595', '#7E7C7C', '#656363'];
	const sedimentTitle = 'Sediment:';

	return (
		<div className="sediment-legend-container" style={bloomLegendShown ? { left: '387px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : { left: '32px' }}>
			<div className="legend-inner-container">
				<span className="legend-text" style={{ marginTop: '14px' }}>
					{' '}
					{sedimentTitle}{' '}
				</span>

				<div style={{ height: '8px', width: '221px', marginLeft: '14px', marginRight: '14px', marginTop: '11px' }}>
					<div style={{ display: 'flex', height: '4px', flexDirection: 'row' }}>
						{colours.map((value, index) => {
							return <div style={{ background: value, width: '37px', height: '8px' }} key={index}></div>;
						})}
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '9px' }}>
						{interferncesValues.map((el) => (
							<span key={el + el} style={{ textAlign: 'center', fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>
								{el}
							</span>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
